.notFound {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notFound_picture {
  margin: 24px;
}
.notFound_picture_image {
  width: 184px;
}
.notFound_content {
  color: #252129;
}
.notFound_content_title {
  font: 600 24px Montserrat;
}
.notFound_content_message {
  font: 400 16px Roboto;
}
