body {
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fafaff;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-VF.woff2");
  /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Montserrat-VF.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Montserrat-VF.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("assets/fonts/Montserrat-SemiBold.woff2");
  /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Montserrat-SemiBold.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("assets/fonts/Montserrat-VF.woff2");
  /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Montserrat-VF.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Montserrat-VF.ttf")
      format("truetype");
  /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.woff2"); /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Roboto-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Roboto-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto SemiBold";
  src: url("assets/fonts/Roboto-Regular.woff2"); /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Roboto-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Roboto-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("assets/fonts/Roboto-Regular.woff2"); /* IE9 Compat Modes */
  src: local(""), url("assets/fonts/Roboto-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Roboto-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.MuiPickersToolbar-root.MuiPickersLayout-toolbar {
  display: none !important;
}
