.notFound {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notFound_picture {
  margin: 24px;
}
.notFound_picture_image {
  width: 184px;
}
.notFound_content {
  color: #252129;
}
.notFound_content_title {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.notFound_content_message {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
}
